
    @tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --primary-color: #3bacb6;
}
/* Override default focus colors for tailwindcss-forms https://github.com/tailwindlabs/tailwindcss-forms */
[type='text']:focus,
[type='email']:focus,
[type='url']:focus,
[type='password']:focus,
[type='number']:focus,
[type='date']:focus,
[type='search']:focus,
[type='checkbox']:focus,
[type='radio']:focus,
[multiple]:focus,
textarea:focus,
select:focus {
  --tw-ring-color: var(--primary-color);
  border-color: var(--primary-color);
}

.react-datepicker-wrapper .react-datepicker__input-container input{
  background-color: transparent;
  border: none;
  width: 100%;
  padding: 0px;
}
.react-datepicker__day.react-datepicker__day--keyboard-selected {
  background: none;
  color: black;
}
input:focus, textarea:focus{
  outline:none !important;
  outline-offset: 0 !important;
  --tw-ring-offset-width:none !important;
}

li:focus-visible{
  outline: none;
}

li:hover{
  cursor: pointer;
}

.tab-panel--selected { animation-name: tabPanel; animation-duration: 300ms; animation-timing-function: linear; }
@keyframes tabPanel { from { opacity: 0; } to { opacity: 1; } }
/* Input style */
.input-container {
  position: relative;
  display: flex;
  flex-direction: column;
}

.input-container label {
  position: absolute;
  transform-origin: top left;
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.input-container input {
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
}

.rowtext_one{
 @apply font-normal italic mb-[3px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] text-white_A700 w-[auto]; 
}
.Image{
 @apply absolute lg:h-[10px] xl:h-[12px] 2xl:h-[14px] 3xl:h-[17px] inset-[0] justify-center m-[auto] w-[56%]; 
}
.Tag{
 @apply font-normal italic mt-[3px] text-white_A700 w-[auto]; 
}
.rowtext_twelve{
 @apply font-medium mb-[3px] lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] text-white_A700 w-[auto]; 
}
.home{
 @apply lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px] lg:px-[3px] 2xl:px-[4px] xl:px-[4px] 3xl:px-[5px] rounded-radius4 w-[100%]; 
}
.Image_Two{
 @apply absolute lg:h-[10px] xl:h-[12px] 2xl:h-[14px] 3xl:h-[17px] inset-[0] justify-center m-[auto] xl:w-[11px] 2xl:w-[13px] 3xl:w-[16px] lg:w-[9px]; 
}
.Left{
 @apply lg:h-[11px] xl:h-[13px] 2xl:h-[15px] 3xl:h-[18px] lg:ml-[5px] xl:ml-[6px] 2xl:ml-[7px] 3xl:ml-[9px] w-[11%]; 
}
.file_One{
 @apply lg:h-[12px] xl:h-[15px] 2xl:h-[17px] 3xl:h-[20px] w-[1%]; 
}
.rowlock{
 @apply font-medium lg:ml-[4px] 2xl:ml-[5px] xl:ml-[5px] 3xl:ml-[6px] text-white_A700 w-[auto]; 
}
.refresh{
 @apply xl:h-[10px] 2xl:h-[11px] 3xl:h-[13px] lg:h-[8px] mr-[4px] w-[1%]; 
}
.FrameTwo{
 @apply lg:h-[13px] xl:h-[17px] 2xl:h-[19px] 3xl:h-[22px] lg:mr-[5px] xl:mr-[6px] 2xl:mr-[7px] 3xl:mr-[9px] w-[7%]; 
}
.logo{
 @apply lg:h-[25px] xl:h-[31px] 2xl:h-[35px] 3xl:h-[42px] lg:ml-[54px] xl:ml-[67px] 2xl:ml-[76px] 3xl:ml-[91px] w-[8%]; 
}
.ctabtn1{
 @apply font-normal lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] not-italic text-gray_801 w-[auto]; 
}
.ctabtn2{
 @apply font-normal lg:mt-[4px] 2xl:mt-[5px] xl:mt-[5px] 3xl:mt-[6px] not-italic text-gray_801 w-[auto]; 
}
.row16pxregularforty_two{
 @apply font-normal not-italic text-gray_801 w-[auto]; 
}
.arrowdown{
 @apply lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px] lg:w-[16px] xl:w-[20px] 2xl:w-[22px] 3xl:w-[27px]; 
}
.user{
 @apply lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:w-[32px] xl:w-[40px] 2xl:w-[45px] 3xl:w-[54px]; 
}
.icon{
 @apply absolute lg:h-[33px] xl:h-[41px] 2xl:h-[46px] 3xl:h-[55px] lg:w-[32px] xl:w-[40px] 2xl:w-[45px] 3xl:w-[54px]; 
}
.rowinline_one{
 @apply border-2 border-solid border-teal_300 flex font-semibold items-center lg:mt-[41px] xl:mt-[52px] 2xl:mt-[59px] 3xl:mt-[70px] xl:px-[11px] 2xl:px-[12px] 3xl:px-[14px] lg:px-[8px] rounded-radius50 text-teal_300 w-[auto]; 
}
.ctabtn4{
 @apply font-bold lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-white_A700 w-[auto]; 
}
.lock2{
 @apply xl:h-[12px] 2xl:h-[13px] 3xl:h-[15px] lg:h-[9px] w-[4%]; 
}
.ctabtn5{
 @apply font-bold lg:mt-[3px] 2xl:mt-[4px] xl:mt-[4px] 3xl:mt-[5px] text-teal_300 w-[auto]; 
}
.ctabtn6{
 @apply font-normal mb-[1px] not-italic text-gray_801 w-[auto]; 
}
.ctabtn7{
 @apply font-normal not-italic text-gray_602 w-[auto]; 
}
.arrowright{
 @apply absolute lg:h-[13px] xl:h-[16px] 2xl:h-[18px] 3xl:h-[21px] rounded-radius8 lg:w-[12px] xl:w-[15px] 2xl:w-[17px] 3xl:w-[20px]; 
}
.ctabtn8{
 @apply font-normal not-italic text-teal_300 w-[auto]; 
}
.getFit{
 @apply font-mavenpro font-semibold ml-[3px] text-white_A700 w-[auto]; 
}
.test_24pxregularForty1{
 @apply font-normal font-opensans lg:mt-[18px] xl:mt-[23px] 2xl:mt-[26px] 3xl:mt-[32px] not-italic text-white_A700 w-[auto]; 
}
.test_16pxregularForty_Six{
 @apply font-normal lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[auto]; 
}
.card{
 @apply bg-bluegray_300 flex items-center xl:ml-[10px] 2xl:ml-[11px] 3xl:ml-[13px] lg:ml-[8px] lg:px-[11px] xl:px-[14px] 2xl:px-[16px] 3xl:px-[19px] rounded-radius50 text-white_A700 w-[60px]; 
}
.undrawAddtoc{
 @apply 3xl:h-[103px] lg:h-[61px] xl:h-[77px] 2xl:h-[86px] rounded-radius16 3xl:w-[102px] lg:w-[60px] xl:w-[76px] 2xl:w-[85px]; 
}
.language_One{
 @apply leading-[180.00%] mb-[1px] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[100%]; 
}
.test_16pxbold700_Three{
 @apply font-bold mt-[1px] text-white_A700 w-[auto]; 
}
.language_Two{
 @apply leading-[180.00%] 2xl:mt-[10px] 3xl:mt-[12px] lg:mt-[7px] xl:mt-[9px] not-italic text-white_A700 w-[96%]; 
}
.test_16pxbold700_Four{
 @apply font-bold mt-[2px] text-white_A700 w-[auto]; 
}
.test_32pxbold7008{
 @apply font-bold font-mavenpro text-gray_801 w-[auto]; 
}
.get_doctor{
 @apply bg-white_A700 items-start lg:p-[10px] xl:p-[13px] 2xl:p-[15px] 3xl:p-[18px] rounded-radius24 shadow-bs w-[100%]; 
}
.photoDoctor{
 @apply absolute lg:h-[101px] xl:h-[126px] 2xl:h-[142px] 3xl:h-[170px] rounded-radius8 w-[100%]; 
}
.test_16pxbold700{
 @apply font-bold leading-[150.00%] mt-[1px] text-gray_801 w-[98%]; 
}
.test_14pxregularForty4{
 @apply leading-[150.00%] 3xl:mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] not-italic text-gray_602 w-[100%]; 
}
.HomeScreenMob{
 @apply absolute lg:h-[580px] xl:h-[725px] 2xl:h-[815px] 3xl:h-[978px] top-[0] w-[100%]; 
}
.description_Two2{
 @apply leading-[180.00%] mb-[1px] lg:mt-[10px] xl:mt-[12px] 2xl:mt-[14px] 3xl:mt-[17px] not-italic text-white_A700 w-[100%]; 
}
.test_16pxbold7004{
 @apply font-bold leading-[150.00%] text-gray_801 w-[84%]; 
}
.test_14pxregularForty9{
 @apply xl:mt-[11px] 2xl:mt-[12px] 3xl:mt-[14px] lg:mt-[8px] not-italic text-gray_602 w-[auto]; 
}
.row16pxregularforty_three{
 @apply font-normal not-italic text-white_A700 w-[auto]; 
}
.brettjordanYOne1{
 @apply xl:h-[102px] 2xl:h-[115px] 3xl:h-[138px] lg:h-[82px] rounded-radius16 xl:w-[101px] 2xl:w-[114px] 3xl:w-[137px] lg:w-[81px]; 
}
.card1{
 @apply font-bold p-[0] lg:text-[13px] xl:text-[16px] 2xl:text-[19px] 3xl:text-[22px] text-gray_801 w-[100%]; 
}
.AppsBanner128{
 @apply lg:h-[136px] xl:h-[170px] 2xl:h-[191px] 3xl:h-[229px] rounded-bl-[16px] rounded-br-[16px] rounded-tl-[24px] rounded-tr-[24px] w-[100%]; 
}
.info{
 @apply font-bold leading-[180.00%] text-gray_801 w-[75%]; 
}
.test_16pxsemiboldSix{
 @apply font-semibold mt-[1px] text-gray_801 w-[auto]; 
}
.test_14pxregularForty18{
 @apply lg:mt-[4px] xl:mt-[5px] 2xl:mt-[6px] 3xl:mt-[8px] not-italic text-bluegray_300 w-[auto]; 
}
.info1{
 @apply font-bold leading-[180.00%] mt-[1px] text-gray_801 w-[100%]; 
}
.info2{
 @apply font-bold leading-[180.00%] text-gray_801 w-[100%]; 
}
.test_14pxregularForty_Three1{
 @apply 3xl:mt-[10px] lg:mt-[6px] xl:mt-[7px] 2xl:mt-[8px] not-italic text-bluegray_300 w-[auto]; 
}
.AppsBanner128_Six{
 @apply lg:h-[163px] xl:h-[204px] 2xl:h-[229px] 3xl:h-[275px] lg:ml-[16px] xl:ml-[20px] 2xl:ml-[22px] 3xl:ml-[27px] rounded-radius16 w-[16%]; 
}
.ctabtn13{
 @apply cursor-pointer font-normal not-italic text-gray_602 w-[auto]; 
}
.ctabtn14{
 @apply cursor-pointer font-normal not-italic text-teal_300 w-[auto]; 
}
.AppsBanner1283{
 @apply lg:h-[170px] xl:h-[212px] 2xl:h-[239px] 3xl:h-[286px] rounded-radius16 w-[38%]; 
}
.column16pxsemiboldsix{
 @apply font-normal leading-[200.00%] lg:mt-[14px] xl:mt-[17px] 2xl:mt-[20px] 3xl:mt-[24px] not-italic text-gray_801 w-[97%]; 
}
.test_24pxbold7006{
 @apply font-bold mb-[1px] lg:mt-[10px] xl:mt-[13px] 2xl:mt-[15px] 3xl:mt-[18px] text-gray_900 w-[auto]; 
}
.ctabtn15{
 @apply font-bold mb-[1px] text-white_A700 w-[auto]; 
}
.info3{
 @apply font-bold leading-[180.00%] text-gray_801 w-[89%]; 
}
.card2{
 @apply bg-bluegray_300 flex font-normal items-center lg:mt-[5px] xl:mt-[6px] 2xl:mt-[7px] 3xl:mt-[9px] not-italic xl:px-[11px] 2xl:px-[12px] 3xl:px-[14px] lg:px-[8px] rounded-radius50 text-gray_801 w-[72px]; 
}
.ctabtn16{
 @apply font-normal ml-[4px] not-italic text-gray_801 w-[auto]; 
}
.test_16pxbold700_One1{
 @apply font-bold font-opensans text-cyan_100 w-[auto]; 
}
.legal{
 @apply font-normal font-opensans not-italic text-teal_300 w-[auto]; 
}
.linkedin{
 @apply absolute lg:h-[17px] xl:h-[21px] 2xl:h-[23px] 3xl:h-[28px] rounded-radius4 lg:w-[16px] xl:w-[20px] 2xl:w-[22px] 3xl:w-[27px]; 
}
