:root { 
--black_900_7f:#0000007f; 
--bluegray_50:#e9eef4; 
--green_100:#c8f0b7; 
--black_900_b2:#000000b2; 
--bluegray_50_66:#e9eef466; 
--red_600:#e14640; 
--green_700:#1dad2c; 
--deep_purple_200:#b4acdd; 
--teal_300:#3bacb6; 
--green_A700:#28ca42; 
--black_900_00:#00000000; 
--yellow_800:#dfa123; 
--teal_900:#033135; 
--deep_orange_102:#ffb6b6; 
--deep_orange_101:#ffb8b8; 
--pink_300:#fd6584; 
--deep_orange_100:#fdd3bc; 
--gray_600:#737373; 
--gray_601:#767676; 
--gray_400:#bfbfbf; 
--gray_401:#cacaca; 
--red_A200:#ff5f57; 
--gray_800:#474a4d; 
--gray_602:#747a7b; 
--gray_900_66:#22222266; 
--gray_801:#384142; 
--lime_900:#915837; 
--gray_603:#9e616a; 
--cyan_100_33:#b3e8e533; 
--cyan_200:#82dbd8; 
--black_900_99:#00000099; 
--blue_100:#c3d7fb; 
--white_A701:#faffff; 
--white_A700:#ffffff; 
--white_A702:#f9feff; 
--gray_52:#fffcfa; 
--gray_51:#f8fdff; 
--deep_orange_50:#f7efe2; 
--gray_50:#fbfbfb; 
--teal_400:#2f8f9d; 
--white_A700_33:#ffffff33; 
--black_900_66:#00000066; 
--black_900:#000000; 
--yellow_700:#ffbd2e; 
--black_901:#010101; 
--gray_700:#6e3b74; 
--gray_500:#9e9e9e; 
--gray_900:#252525; 
--light_blue_50:#e8fdff; 
--teal_50:#daf0f2; 
--gray_300:#e6e6e6; 
--bluegray_900:#2f2e41; 
--white_A700_00:#ffffff00; 
--orange_50:#ffeadb; 
--gray_100_26:#f5f5f526; 
--black_900_33:#00000033; 
--cyan_100:#b3e8e5; 
--bluegray_300:#a2abac; 
--cyan_901:#005c7a; 
--cyan_900:#23676d; 
}